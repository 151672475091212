import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ErrorComponent } from "./error/error.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  {
    path: "about",
    loadChildren: () =>
      import("./aboutus/aboutus.module").then(m => m.AboutusModule)
  },
  {
    path: "services",
    loadChildren: () =>
      import("./services/services.module").then(m => m.ServicesModule)
  }
  ,
  {
    path: "projects",
    loadChildren: () =>
      import("./projects/projects.module").then(m => m.ProjectsModule)
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./contact-us/contact-us.module").then(m => m.ContactUsModule)
  },
  { path: "**", component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
