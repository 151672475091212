import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-project-type-box',
  templateUrl: './project-type-box.component.html',
  styleUrls: ['./project-type-box.component.scss']
})
export class ProjectTypeBoxComponent implements OnInit {
  @Input() Name = "";
  @Input() Count = "";
  @Input() fileNameWithoutSpaces = "assets/images/breadcrumbs/2.jpg";
  constructor() { }

  ngOnInit() {
  }

}
