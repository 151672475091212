import { Component, Input, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { SendEmailService } from '../send-email.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'tsu-project-box',
  templateUrl: './project-box.component.html',
  styleUrls: ['./project-box.component.scss']
})
export class ProjectBoxComponent implements OnInit {
  @Input() Name = "test";
  @Input() Area = "";
  @Input() Size = "";
  @Input() Type = "";
  @Input() Status = "";
  @Input() Price = "";
  @Input() fileNameWithoutSpaces = "assets/images/breadcrumbs/2.jpg";

  email: string;
  name: string;
  number: string;
  data: FormGroup;
  
  constructor( private httpClient: HttpClient,
    public dialog: MatDialog,
    private contactformTile: SendEmailService,
    private toastrService: ToastrService) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "350px",
      data: { data: this.data },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      this.data = result;
      this.email = this.data.get("email").value;
      this.name = this.data.get("name").value;
      this.number = this.data.get("number").value;
      this.sendEmail();
    });
  }

  sendEmail(): void {
    if (this.data.valid && this.data.dirty) {
      this.toastrService.info("Form submit is in progress", "Wait");
      this.data.disable();
      this.contactformTile.sendEmail(this.data.value).subscribe(response => {
        if (response) {
          this.data.reset();
          console.error(response);
        } else {
          this.toastrService.success(
            "Thanks for sending email. We will contact you soon!",
            "Success"
          );
          this.data.enable();
          this.data.reset();
        }
      });
    } else {
      this.toastrService.error(
        "Please add all required details in the Contact Form!",
        "Failed"
      );
    }
  }

}
