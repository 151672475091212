import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { ErrorComponent } from "./error/error.component";
import { WhyChooseusComponent } from "./why-chooseus/why-chooseus.component";
import { HomeSliderComponent } from "./home-slider/home-slider.component";
import { ReviewComponent } from "./review/review.component";
import { SharedModuleModule } from "./shared-module/shared-module.module";
import { BannerComponent } from "./shared-module/banner/banner.component";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { TestimonialBoxComponent } from './testimonial-box/testimonial-box.component';
import { DialogComponent } from "./dialog/dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ErrorComponent,
    WhyChooseusComponent,
    HomeSliderComponent,
    ReviewComponent,
    BannerComponent,
    TestimonialBoxComponent,
    DialogComponent
  ],
  imports: [BrowserModule, AppRoutingModule, SharedModuleModule,HttpClientModule,BrowserAnimationsModule,
    ToastrModule.forRoot()],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule {}
