import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModuleRoutingModule } from "./shared-module-routing.module";
import { ToastrModule } from "ngx-toastr";
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatSliderModule,
  MatRadioModule,
  MatDialogModule,
  MatGridListModule,
  MatExpansionModule,
  MatTabsModule
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { BannerComponent } from './banner/banner.component';
import { HomeAboutusComponent } from './home-aboutus/home-aboutus.component';
import { HomeContactComponent } from './home-contact/home-contact.component';
import { HomeServicesComponent } from './home-services/home-services.component';
import { BannerCoverComponent } from "../banner-cover/banner-cover.component";
import { CounterComponent } from "../counter/counter.component";
import { HomeProjectsComponent } from "./home-projects/home-projects.component";
import { ProjectBoxComponent } from "../project-box/project-box.component";
import { ClientsComponent } from "../clients/clients.component";
import { PropertyTypeComponent } from "../property-type/property-type.component";
import { ProjectTypeBoxComponent } from "../project-type-box/project-type-box.component";
import { MaterialModule } from "../material/material.module";


@NgModule({
  declarations: [
  HomeAboutusComponent,
  HomeContactComponent,
  HomeServicesComponent,
  BannerCoverComponent,
  CounterComponent,
  HomeProjectsComponent,
  ProjectBoxComponent,
  ClientsComponent,
  PropertyTypeComponent,
  ProjectTypeBoxComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
   SharedModuleRoutingModule,
   MaterialModule,
    NgbModule,
    CarouselModule,
    ToastrModule.forRoot()
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CarouselModule,
    MaterialModule,
    BannerCoverComponent,
    CounterComponent,
    HomeProjectsComponent,
    ProjectBoxComponent,
    ClientsComponent,
    PropertyTypeComponent,
    ProjectTypeBoxComponent
  ]
})
export class SharedModuleModule {}
