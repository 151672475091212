import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-home-aboutus',
  templateUrl: './home-aboutus.component.html',
  styleUrls: ['./home-aboutus.component.scss']
})
export class HomeAboutusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
