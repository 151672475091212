import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-testimonial-box',
  templateUrl: './testimonial-box.component.html',
  styleUrls: ['./testimonial-box.component.scss']
})
export class TestimonialBoxComponent implements OnInit {
  @Input() Name = "";
  @Input() Position = "";
  @Input() Testimonial = "";
  @Input() fileNameWithoutSpaces = "";

  ngOnInit() {
  }

}
