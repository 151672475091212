import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-home-projects',
  templateUrl: './home-projects.component.html',
  styleUrls: ['./home-projects.component.scss']
})
export class HomeProjectsComponent implements OnInit {
  productItems: any;
  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.getProducts();
  }

  private getProducts() {
    this.httpClient.get("assets/json/product.json").subscribe(data => {
      this.productItems = data;
      console.log("data", data);
    });
  }

}
