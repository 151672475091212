import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-banner-cover',
  templateUrl: './banner-cover.component.html',
  styleUrls: ['./banner-cover.component.scss']
})
export class BannerCoverComponent implements OnInit {
  @Input() Title = "test";
  @Input() SubTitle = "";
  @Input() className = "";
  constructor() { }

  ngOnInit() {
  }

}
