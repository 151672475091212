import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'circleink-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {
  tileForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormGroup,
    private fb: FormBuilder) { }

  onNoClick(): void {
    this.dialogRef.close({
      data: this.tileForm
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.tileForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.email, Validators.required]],
      number: [""],
      toMail: ["sales@beyondhomesrealty.in"],
      ccMail: [""]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
